<template>
  <div class="gq-news-detail-phone-body">
    <div class="gq-news-img-box">
      <img :src="itemData.infoBackground" alt="" />
    </div>
    <div class="gq-news-detail-box">
      <div class="gq-news-detail-btn-box">
        <div
          class="gq-news-detail-btn"
          v-for="(item, i) in itemData.infoLabel"
          :key="i"
          :class="i != 0 ? 'left' : ''"
        >
          {{ item }}
        </div>
      </div>
      <div class="gq-news-detail-name">
        {{ itemData.infoTitle }}
      </div>
      <div class="gq-news-detail-txt" v-html="itemData.infoContent"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      itemData: {},
    };
  },
  mounted() {
    this.itemData = this.$route.params;
    if (!Array.isArray(this.itemData.infoLabel)) {
      this.itemData.infoLabel = this.itemData.infoLabel
        ? this.itemData.infoLabel.split(",")
        : [];
    }
  },
};
</script>
<style lang="less" scoped>
.gq-news-detail-phone-body {
  .gq-news-img-box {
    height: 8.45rem;
    width: 10rem;
    img {
      height: 8.45rem;
      width: 10rem;
    }
  }
  .gq-news-detail-box {
    padding-top: 0.8rem;
    padding-left: 0.27rem;

    width: 9.44rem;
    .gq-news-detail-btn-box {
      display: flex;
      text-align: center;
      .gq-news-detail-btn {
        width: 1.55rem;
        height: 0.53rem;
        background: #ffffff;
        border: 1px solid #ff9100;
        font-size: 0.37rem;
        font-weight: 300;
        line-height: 0.53rem;
        color: #ff9100;
      }
      .left {
        margin-left: 0.27rem;
      }
    }
    .gq-news-detail-name {
      padding-top: 0.4rem;
      font-size: 0.59rem;
      font-weight: bold;
      color: #222222;
    }
    .gq-news-detail-txt {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      font-size: 0.37rem;
      font-weight: 300;
      color: #222222;
    }
  }
}
</style>